import React from "react"
import { graphql } from "gatsby"
import MDReactComponent from "markdown-react-js"

// @Material UI
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

// @Local
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopButtons from "../components/singles/topButtons"
import BlockNews from "../components/singles/latestNews"
import { LikeButton, FavButton } from "../components/actions/Actions"
import { getDateFormat } from "../utils/date"

export default function NewsTpl({ data }) {
  if (!data) {
    return (
      <Paper>
        <h2>...</h2>
      </Paper>
    )
  }

  let { title, content, date, image, strapiId, fields } = data.strapiArticle
  if (fields && fields.slug) {
    // window.history.pushState("", "", '/actualidad/' + fields.slug); // @todo
  }
  return (
    <Layout section="Actualidad" sectionLink={"/app/actualidad"}>
      <SEO title={title} />
      <Grid container spacing={3} className="page-detail news-detail">
        <Grid item xs={12} sm={7} lg={8} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            {title}
          </Typography>
          <div className="page-date">
            {date && <p>{getDateFormat(date)}</p>}
          </div>
          <div className="page-image">
            {image && image.publicURL && (
              <img alt={"Some"} src={image.publicURL} width="100%" />
            )}
          </div>
          <div className="page-markdown">
            {content && <MDReactComponent text={content} />}
          </div>
          <div className="page-actions">
            <LikeButton strapiId={strapiId} type={"News"} title={title} />
            <FavButton strapiId={strapiId} type={"News"} title={title} />
          </div>
        </Grid>
        <Grid item xs={12} sm={5} lg={4} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
          <BlockNews />
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      strapiId
      title
      content
      date
      extlink
      image {
        publicURL
        prettySize
      }
      fields {
        slug
      }
    }
  }
`
